<template>
    <div class="register">
        <notice :title-left="true" label="register_page_tips" />
        <el-form label-position="top" :model="userForm" ref="userForm" :rules="rules">
            <div class="form flex">
              <el-form-item label="名称" label-width="300px" prop="username">
                <div class="input"><el-input v-model="userForm.username"></el-input></div>
              </el-form-item>
              <el-form-item label="密码" prop="password">
                <div class="input"><el-input type="password" v-model="userForm.password" :show-password="true"></el-input></div>
              </el-form-item>
              <el-form-item label="联系人手机" prop="mobile">
                <div class="input"><el-input v-model="userForm.mobile"></el-input></div>
              </el-form-item>
              <el-form-item label="密码（再次输入）" prop="repassword">
                <div class="input"><el-input type="password" v-model="userForm.repassword" :show-password="true"></el-input></div>
              </el-form-item>
              <el-form-item label="联系人邮箱" prop="email">
                <div class="input flex">
                  <el-input v-model="userForm.email"></el-input>
                  <el-button class="sendMsg" @click="sendMsg" type="primary" :disabled="prohibitClick">{{ isReSend ? countDown + 's' : '发送验证码' }}</el-button>
                </div>
              </el-form-item>
              <el-form-item label="验证码" prop="code">
                <div class="input"><el-input v-model="userForm.code"></el-input></div>
              </el-form-item>
            </div>
        </el-form>
        <div class="registerBtn">
            <el-button type="primary" class="buttonClass" @click="submitForm('userForm')" :loading="registerLoading">注册</el-button>
        </div>
    </div>
</template>

<script>
import { json } from 'body-parser'
import notice from '../components/Notice.vue'

export default {
  name: 'register',
  components: {
    notice
  },
  data() {
    var validatereEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入联系人邮箱！'))
      } else if (!this.isEmailAvailable(value)) {
        callback(new Error('请输入正确的邮箱！'))
      } else {
        callback()
      }
    }
    var validaterePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码！'))
      } else if (!this.checkPhone(value)) {
        callback(new Error('请输入正确的手机号码！'))
      } else {
        callback()
      }
    }
    var validaterePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.userForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      userForm: {
        username: '',
        password: '',
        mobile: '',
        repassword: '',
        email: '',
        code: ''
      },
      rules: {
        username: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        mobile: [{ validator: validaterePhone, trigger: 'blur', required: true }],
        repassword: [{ validator: validaterePass, required: true, trigger: 'blur' }],
        email: [{ validator: validatereEmail, required: true, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      registerLoading: false,
      countDown: 60,
      timer: null,
      isReSend: false
    }
  },
  computed: {
    prohibitClick() {
      return !this.userForm.email || !this.isEmailAvailable(this.userForm.email) || this.isReSend
    }
  },
  destroyed() {
    if (this.timer) {
      window.clearInterval(this.timer)
    }
  },
  methods: {
    sendMsg() {
      this.$api.sendEms({ email: this.userForm.email }).then(res => {
        this.isReSend = true
        this.timer = setInterval(() => {
          if (this.countDown === 1) {
            this.countDown = 60
            clearInterval(this.timer)
            this.isReSend = false
          }
          this.countDown--
        }, 1000)
      })
    },
    isEmailAvailable(obj) {
      const str = new RegExp('^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$')
      if (!str.test(obj)) {
        return false
      }
      return true
    },
    checkPhone(phone) {
      if (!(/^1[3456789]\d{9}$/.test(phone))) {
        return false
      } else {
        return true
      }
    },
    submitForm(formName) {
      const that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registerLoading = true
          this.$api.register(this.userForm).then(res => {
            this.registerLoading = false
            this.$message({
              message: '注册账号成功',
              type: 'success'
            })
            localStorage.setItem('userInfo', JSON.stringify(res.userinfo))
            localStorage.setItem('token', res.userinfo.token)
            setTimeout(() => {
              this.$router.push('/home')
            }, 1000)
          }).catch(() => {
            this.registerLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sendMsg {
  // width: 80px;
  margin-left: 10px;
}
.register {
   width: 800px;
   margin: 0 auto;
   box-sizing: border-box;
   padding-top: 20px;
}
.form {
  margin-top: 40px;
  flex-wrap: wrap;
}
.input {
  width: 350px;
}
.el-form-item {
  &:nth-child(2n) {
    margin-left: 100px;
  }
}
.registerBtn {
  margin: 100px auto;
  text-align: center;
  .buttonClass {
    width: 360px;
    height: 50px;
  }
}
</style>
